import React, { useState, useEffect, useRef } from "react";
import { Redirect, useLocation } from "react-router-dom";
import axios from "axios";
import jwt from "jsonwebtoken";
import Notiflix from "notiflix";
import moment from "moment";
import { createBrowserHistory } from "history";
import Swal from "sweetalert2";
import { v4 as uuidv4 } from "uuid";
import PubIP from "public-ip";
import config from "../config";
import AppLog from "../logs";

Notiflix.Notify.Init({
  fontFamily: 'Prompt",sans-serif',
  fontSize: "20px",
  position: "center-top",
});

export default function AppCode({ eventid }) {
  const [event, setEvent] = useState(null);
  const [code, setCode] = useState("");
  const [success, setSuccess] = useState(false);
  const [publicIP, setIP] = useState("");
  const [rerunCode, setRerunCode] = useState(null);
  const location = useLocation();
  const inputCode = useRef(null);

  useEffect(() => {
    if (event === null) {
      getEventInfo();
    }
    const page = window.location.pathname + window.location.search;
    getClientIP();
  }, []);

  useEffect(() => {
    if (event !== null) {
      document.title = event.name;
    }
  }, [event]);

  useEffect(() => {
    if (rerunCode !== null) {
      findUserExistsInWebSocket();
    }
  }, [rerunCode]);

  const getEventInfo = async () => {
    const url = `${config.AWS_LIVE_API_HOST}${config.AWS_API_ENDPOINT.GET_EVENTINFO}${eventid}`;
    try {
      const res = await axios.get(url);
      if (res.status === 200) {
        setEvent(res.data);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  const getClientIP = async () => {
    // console.log('your ip is: ' + await PubIP.v4());
    setIP(await PubIP.v4());
  };

  const handleCode = (e) => {
    setCode(e.target.value.toUpperCase());
  };

  const verifyForm = async () => {
    if (code === "") {
      Notiflix.Notify.Warning("Please enter your code");
      inputCode.current.focus();
      return;
    } else if (code.trim().length > 15) {
      Notiflix.Notify.Warning("Code invalid format");
      inputCode.current.focus();
      return;
    }

    await verifyCode(code.trim());
  };

  const verifyCode = async (_code) => {
    const url =
      config.AWS_LIVE_RERUN_HOST + config.AWS_API_ENDPOINT.LOGIN_EXPIRED;
    const params = {
      eventid: eventid.toString(),
      code: _code,
    };
    try {
      const res = await axios.post(url, params);
      if (res.status === 200) {
        setRerunCode(res.data);
      }
    } catch (e) {
      if (e.response.status === 404) {
        AppLog.insert(
          eventid.toString(),
          publicIP,
          "failure",
          `[EnterCode] code "${_code}" not found`
        );
        Notiflix.Notify.Warning("Your code is invalid");
      }
      if (e.response.status === 405) {
        AppLog.insert(
          eventid.toString(),
          publicIP,
          "failure",
          `[EnterCode] code "${_code}" is timeout`
        );
        Notiflix.Notify.Failure("Your code is timeout");
      }
      if (e.response.status === 406) {
        AppLog.insert(
          eventid.toString(),
          publicIP,
          "failure",
          `[EnterCode] code "${_code}" is timeout`
        );
        Notiflix.Notify.Failure("Your code is timeout.");
      } else {
        AppLog.insert(
          eventid.toString(),
          publicIP,
          "failure",
          `[EnterCode] code "${_code}" ${e.message}`
        );
      }
    }
  };

  const findUserExistsInWebSocket = async () => {
    const url =
      config.AWS_LIVE_API_HOST + config.AWS_API_ENDPOINT.WEBSOCKET_USERS + code;
    try {
      const res = await axios.get(url);
      if (res.status === 200) {
        Swal.fire({
          width: "720px",
          allowOutsideClick: false,
          title: "Your account is in use on another device.",
          text: "If you've shared your account with friends or family, they may be using TTM Live while you're trying to watch a live streaming. To solve this problem, make sure that no one else is using your account before trying to stream again.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Continue with this session",
        }).then(async (result) => {
          if (result.value) {
            isKickPrevWebsocketUser(res.data);
          }
        });
      }
    } catch (e) {
      if (e.response.status === 404) {
        createToken();
      } else {
        Notiflix.Notify.Failure("Verify code failed");
        return;
      }
    }
  };

  const deleteWebsocketUser = async (params) => {
    const url =
      config.AWS_LIVE_API_HOST + config.AWS_API_ENDPOINT.WEBSOCKET_USERS;
    try {
      await axios.delete(url, { data: params });
      return true;
    } catch (e) {
      console.log("remove ws connection failed: " + e.message);
      return false;
    }
  };

  const isKickPrevWebsocketUser = async (params) => {
    const uuid = uuidv4();
    const ws = new WebSocket(`${config.WSS_LINK_SYSTEM}${uuid}`);
    ws.onopen = async () => {
      // console.log('connected')
      try {
        const message = {
          data: "AccessDenied",
          receiver: code,
          action: "sendmsg",
        };
        ws.send(JSON.stringify(message));
        // console.log('send message: ok');
        ws.close();
        // console.log('ws was closed');
        const delResult = await deleteWebsocketUser(params);
        if (delResult) {
          createToken();
        }
      } catch (e) {
        console.log(e.message);
        return false;
      }
    };
  };

  const createToken = () => {
    const data = {
      ehId: eventid,
      rdId: rerunCode.roundid,
      urId: code.trim().toUpperCase(),
      publicIP: publicIP,
      iat: moment().unix(),
    };

    // SET NEW LOCAL TOKEN
    const token = jwt.sign(data, config.JWT_STORAGE_SECRETKEY);
    sessionStorage.setItem("_tkr" + eventid.toString(), token);

    // LOGS
    AppLog.insert(
      eventid.toString(),
      publicIP,
      "success",
      `[EnterCode] ${JSON.stringify(data)}`
    );
    setSuccess(true);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      verifyForm();
    }
  };

  if (success) {
    return <Redirect to={`/${location.pathname.split("/")[1]}`} />;
  } else {
    return (
      <>
        <link rel="stylesheet" href="/assets/css/bootstrap/bootstrap.min.css"></link>
        <link rel="stylesheet" href="/assets/css/theme/geminifourthruntheworldconcert/style/style.css"></link>

        <main className="main-container" role="main">
          <div className="container mb-3 mb-md-0">
            <div className="row justify-content-center d-flex d-md-none">
              <div className="col-12">
                <p className="text-center my-2">
                  <img className="logo mx-auto" src="/assets/img/logo-ttm-live-dark.svg" alt="" />
                </p>
              </div>
            </div>
            <div className="row justify-content-center no-gutters">
              <div className="col-11 col-lg-5">
                <img src="/assets/css/theme/geminifourthruntheworldconcert/img/aw.jpg" alt="" />
              </div>
              <div className="col-11 col-lg-4 box-content">
                <div className="row justify-content-center h-100">
                  <div className="col-12 align-self-center">
                    <div className="py-0 py-sm-4 px-0 px-lg-2">
                      <p className="text-center d-none d-md-block">
                        <img className="logo mx-auto" src="/assets/img/logo-ttm-live-dark.svg" alt="" />
                      </p>
                      <div className="row justify-content-center">
                        <div className="col-10">
                          <form action="" validate={"true"} onSubmit={(e) => { e.preventDefault(); }} >
                            <p className="text-center my-2">
                              <input type="text" className="form-control form-control-lg text-center" onKeyDown={handleKeyDown} onChange={(e) => { handleCode(e); }}
                                value={code} placeholder="ENTER CODE" ref={inputCode} maxLength={15} required />
                            </p>
                            <p className="text-center my-2">
                              <button onClick={() => verifyForm()} className="btn btn-enter btn-lg btn-block" type="button" >
                                <strong>CONFIRM</strong>
                              </button>
                            </p>
                          </form>
                        </div>
                      </div>
                      <p className="text-center mt-2 text-white text-fade"><small>© 2024 <a className="text-white" href="https://www.thaiticketmajor.com/" target="blank">THAITICKETMAJOR.COM</a></small></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    );
  }
}

