import React, { useState, useEffect, useRef } from "react";
import Player from "./player";
import Merchandise from './merchandise';
import useOutsideClick from '../components/useoutsideclick';
import Timer from '../components/timer';

export default function Main({ event, user }) {

    const [dropdownSubActive, setDropdownSubActive] = useState(false);
    const [subCode, setSubCode] = useState('NO');
    const [subtitle, setSubTitle] = useState('NO');
    const [title, setTitle] = useState('');
    const dropdownRef = useRef();
    const outsideClick = useOutsideClick(dropdownRef);

    useEffect(() => {
        if (event !== null) {
            setTitle(event.name);
        }
    }, [event]);

    useEffect(() => {
        setDropdownSubActive(!outsideClick);
    }, [outsideClick]);

    const handleSetSubTitle = (subcode) => {
        switch (subcode) {
            case 'NO':
                setSubTitle('NO');
                break;
            case 'EN':
                setSubTitle('EN');
                break;
            case 'CN':
                setSubTitle('CN');
                break;
            default:
                setSubTitle('NO');
                break;
        }
        setSubCode(subcode);
        setDropdownSubActive(!dropdownSubActive);
    }

    return (


        <>
            <link rel="stylesheet" href="/assets/css/bootstrap/bootstrap.min.css"></link>
            <link rel="stylesheet" href="/assets/live-stream/css/main.css"></link>
            <link rel="stylesheet" href="/assets/css/theme/weareforeverfancon/style/main.css"></link>

            <div className="main">
                <main className="main-container ttm-live-stream" roles="main" id="watch">
                    <div className="container-fluid">
                        <div className="d-none d-md-block">
                            <div className="row justify-content-center">
                                <div className="col-auto align-self-center">
                                    <p className="mt-2 mb-3">
                                        <img src="/assets/img/logo-ttm-live-dark.svg" alt="" width="64" className="mx-auto" />
                                    </p>
                                </div>
                                <div className="col align-self-center">
                                    <h1 className="h4 align-middle my-2 text-right">
                                        <span className="align-middle d-inline-block">WE ARE FOREVER FANCON</span>
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-player">
                                <div className="player-container">
                                    <Player event={event} subtitleCode={subCode} />
                                </div>
                                
                                {/* <div className="row px-2 px-md-0">
                                    <div className="col-12">
                                        <div className="text-center my-2">
                                            <button type="button" className={subCode === 'NO' ? 'btn-select-player btn btn-dark btn-sm' : 'btn-select-player btn btn-secondary btn-sm'}
                                                onClick={() => { handleSetSubTitle('NO') }}>&nbsp;No Subtitle&nbsp;
                                            </button>&nbsp;&nbsp;
                                            <button type="button" className={subCode === 'EN' ? 'btn-select-player btn btn-dark btn-sm' : 'btn-select-player btn btn-secondary btn-sm'}
                                                onClick={() => { handleSetSubTitle('EN') }}> &nbsp;English Subtitle&nbsp;
                                            </button>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>

                    </div>
                    <div class="row justify-content-center">
                        &nbsp;
                    </div>
                    <div className="row">
                        <div className="col-12 text-center">
                            <div style={{ color: '#fff' }}>Remaining Time : <Timer eventid={user.ehId} code={user.urId} /></div>
                        </div>
                    </div>
                    <div className="row">&nbsp;</div>
                    <div className="row">
                        <div className="col-12 text-center">
                            <div style={{ color: '#fff' }}>กรณีต้องการหยุดรับชม ให้ท่านปิดแท็บ หากกด "หยุด" เวลาจะยังคงเดินต่อไป / To stop watching, please close the tab. Pressing "Pause" button will make the time continue counting down.</div>
                        </div>
                    </div>
                    <div className="row">&nbsp;</div>
                </main>
            </div>
        </>
    )
}