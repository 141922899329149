import React, { useState, useEffect } from "react";
import { Redirect, useLocation } from "react-router-dom";
import Countdown, { zeroPad } from "react-countdown";
import axios from 'axios';

import config from '../config';

export default function Timer({ eventid, code }) {

    const [Timer, setTimer] = useState(null);
    const location = useLocation();

    useEffect(() => {
        if (Timer === null) {
            getTimer();
        }
    }, [Timer])

    const getTimer = async () => {
        const url = `${config.AWS_LIVE_RERUN_HOST}${config.AWS_API_ENDPOINT.GET_TIMER.replace('{eventid}', eventid.toString()).replace('{code}', code)}`;
        try {
            const res = await axios.get(url);
            if (res.status === 200) {
                // console.log(res.data);
                setTimer(parseInt(res.data.remaining_time));
            }
        } catch (e) {
            console.log(e.message);
        }
    }

    const updateTimer = async () => {
        const url = `${config.AWS_LIVE_RERUN_HOST}${config.AWS_API_ENDPOINT.UPDATE_TIMER}`;
        const params = {
            eventid: eventid.toString(),
            code: code
        };
        try {
            const res = await axios.post(url, params);
            console.log('update timer');
        } catch (e) {
            console.log(e.message);
        }
    }

    const onRender = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            updateTimer();
            sessionStorage.removeItem(`_tkr${eventid.toString()}`);
            return <Redirect to={`/${location.pathname.split("/")[1]}/code`} />
        } else {
            return (
                <span>
                    {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
                </span>
            );
        };
    }

    const onTicker = ({ minutes, seconds, total }) => {
        // console.log(`min:${minutes} > sec:${seconds}`);
        if (seconds === 0) {
            updateTimer();
        }
    };

    return (
        Timer !== null ? <Countdown
            date={Date.now() + (Timer * 1000)}
            renderer={(props) => onRender(props)}
            onTick={(props) => onTicker(props)}
        /> : <></>
    );
}
