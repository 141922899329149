import React, { useState, useEffect, useContext } from "react";
import { Redirect, useLocation } from "react-router-dom";
import useWebSocket, { ReadyState } from "react-use-websocket";
import moment from "moment";
import axios from "axios";
import Notiflix from "notiflix";
import config from "../config";
import { StoreContext } from "../context/store";

async function handleRemoveWSConnection(connectionId, userid) {
  const url =
    config.AWS_LIVE_API_HOST + config.AWS_API_ENDPOINT.WEBSOCKET_USERS;
  const params = {
    connectionId: connectionId,
    userid: userid,
  };

  try {
    const res = await axios.delete(url, { data: params });
    console.log("remove ws connection stauts: " + res.status);
  } catch (e) {
    console.log("remove ws connection failed: " + e.message);
    // if(e.response.status === 404) {
    //   window.location = '/limitaccess';
    // }
  }
}

let _interval;
export default function Websocket({ user, eventid, getStatus, getMessage }) {
  const [seconds, setSeconds] = useState(0);
  const { websocketStore, messageStore } = useContext(StoreContext);
  const location = useLocation();

  const { lastMessage, readyState, sendMessage } = useWebSocket(
    `${config.WSS_LINK.replace('{id}', user.urId).replace('{eventid}', eventid)}`,
    {
      shouldReconnect: (e) => true,
      reconnectAttempts: 60,
      reconnectInterval: 5000,
      onOpen: (e) => {
        console.log(`ws onOpen [${moment().format("h:mm:ss a")}]`);
      },
      onClose: (e) => {
        console.log(`ws onClose [${moment().format("h:mm:ss a")}]`);

        const connectionId = sessionStorage.getItem("_ws");
        if (connectionId !== null) {
          handleRemoveWSConnection(connectionId, user.urId);
        } else {
          // Notiflix.Report.Warning(
          //   "Access Warning",
          //   "If you've shared your account with friends or family, they may be using TTM Live while you're trying to watch a live streaming. To solve this problem, make sure that no one else is using your account before trying to stream again.",
          //   "OK"
          // );
        }
      },
      onMessage: (e) => {
        // console.log('-onMessage-');
        console.log(`ws onMessage [${moment().format("h:mm:ss a")}]`);
      },
      onError: (e) => {
        // console.log('-onError-');        
        console.log(`ws onError [${moment().format("h:mm:ss a")}]`);
      },
    }
  );

  useEffect(() => {
    window.addEventListener("offline", function (e) {
      console.log("-- offline --");
    });

    window.addEventListener("online", function (e) {
      console.log("-- online --");
    });
  }, []);

  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];

  useEffect(() => {
    // console.log(seconds);
    async function resetHeartbeat() {
      await stopHeartbeat();
      await startHeartbeat();
    }
    if (seconds >= 180) {
      // *** HEARTBEAT MESSAGE EVERY 3 MINS
      const msg = {
        // data: JSON.stringify({ id: uuidv4(), message: "ping" }),
        data: "ping",
        receiver: user.urId,
        action: "sendmsg",
      };
      sendMessage(JSON.stringify(msg));
      // console.log(`ping message was send [${moment().format('h:mm:ss a')}]`);
      resetHeartbeat();
    }
  }, [seconds]);

  // *** CHECK STATUS
  useEffect(() => {
    // console.log("wss state: " + connectionStatus);
    if (readyState === ReadyState.OPEN) {
      startHeartbeat();
    }

    getStatus(connectionStatus);
  }, [readyState, connectionStatus]);

  // *** GET MESSAGE
  useEffect(() => {
    // console.log(lastMessage);
    if (lastMessage !== null && lastMessage.data !== "ping") {
      // const Msg = JSON.parse(lastMessage.data);
      // if (Msg.message !== 'ping') {
      websocketStore[1](lastMessage.data);
      // websocketStore[1](lastMessage.data.split("'").join('"'));
    }
  }, [lastMessage]);

  useEffect(() => {
    // console.log('send message: ' + JSON.stringify(messageStore[0]))
    if (messageStore[0] !== "") {
      sendMessage(messageStore[0].message);
      // stopHearBeat();
      // startHeartBeat();
    }
  }, [messageStore[0]]);

  const stopHeartbeat = () => {
    clearInterval(_interval);
    setSeconds(0);
  };

  const startHeartbeat = () => {
    _interval = setInterval(() => {
      setSeconds((seconds) => seconds + 1);
    }, 1000);
  };

  return <></>;
}
