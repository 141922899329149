import React, { useState, useEffect, useRef } from "react";
import Player from "./player";
import Merchandise from "./merchandise";
import useOutsideClick from "../components/useoutsideclick";
import Timer from "../components/timer";

export default function Main({ event, user }) {
  const [dropdownSubActive, setDropdownSubActive] = useState(false);
  const [subCode, setSubCode] = useState("thai");
  const [subtitle, setSubTitle] = useState("thai");
  const [title, setTitle] = useState("");
  const dropdownRef = useRef();
  const outsideClick = useOutsideClick(dropdownRef);

  useEffect(() => {
    if (event !== null) {
      setTitle(event.name);
    }
  }, [event]);

  useEffect(() => {
    setDropdownSubActive(!outsideClick);
  }, [outsideClick]);

  return (
    <>
      <link
        rel="stylesheet"
        href="/assets/css/bootstrap/bootstrap.min.css"
      ></link>
      <link rel="stylesheet" href="/assets/live-stream/css/main.css"></link>
      <link
        rel="stylesheet"
        href="/assets/css/theme/sarochabackstage/style/main.css"
      ></link>

      <div className="main">
        <main
          className="main-container ttm-live-stream"
          roles="main"
          id="watch"
        >
          <div className="container-fluid">
            <div className="d-none d-md-block">
              <div className="row justify-content-center">
                <div className="col-12 col-xl-8 col-xxl-9">
                  <div className="row justify-content-center align-items-center">
                    <div className="col">
                      <p className="my-3">
                        <img
                          src="/assets/css/theme/sarochabackstage/img/logo.png"
                          height="64"
                          alt=""
                        />
                      </p>
                    </div>
                    <div className="col-auto">
                      <img
                        src="/assets/css/theme/sarochabackstage/img/sponsor.png"
                        height="40"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
            <div className="row">
              <div className="col-player">
                <div className="player-area">
                  <div className="row justify-content-center">
                    <div className="col-12 col-xl-8 col-xxl-9">
                      <div className="player-container">
                        <div className="player">
                          <Player event={event} subtitleCode={subCode} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row px-2 px-md-0">
                  <div className="col-12">
                    <div className="text-center my-2">
                      <button
                        type="button"
                        className={
                          subCode === "thai"
                            ? "btn-select-player btn btn-dark btn-sm"
                            : "btn-select-player btn btn-secondary btn-sm"
                        }
                        onClick={() => {
                          setSubCode("thai");
                        }}
                      >
                        &nbsp;Thai&nbsp;
                      </button>
                      &nbsp;&nbsp;
                      <button
                        type="button"
                        className={
                          subCode === "english"
                            ? "btn-select-player btn btn-dark btn-sm"
                            : "btn-select-player btn btn-secondary btn-sm"
                        }
                        onClick={() => {
                          setSubCode("english");
                        }}
                      >
                        {" "}
                        &nbsp;English&nbsp;
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <div className="row justify-content-center">&nbsp;</div>
          <div className="row">
            <div className="col-12 text-center">
              <div style={{ color: "#f00" }}>
                Remaining Time : <Timer eventid={user.ehId} code={user.urId} />
              </div>
            </div>
          </div>
          <div className="row">&nbsp;</div>
          <div className="row">
            <div className="col-12 text-center">
              <div style={{ color: "#f00" }}>
                กรณีต้องการหยุดรับชม ให้ท่านปิดแท็บ หากกด "หยุด"
                เวลาจะยังคงเดินต่อไป / To stop watching, please close the tab.
                Pressing "Pause" button will make the time continue counting
                down.
              </div>
            </div>
          </div>
          <div className="row">&nbsp;</div>
          <div className="row justify-content-center">
            <div className="col-12 col-xl-9">
              <p className="text-center">
                <img src="/assets/css/theme/sarochabackstage/img/footer.jpg" alt="" />
              </p>
            </div>
            <div className="col-12 d-block d-md-none">
              <div className="row justify-content-center">
                <div className="col-12 align-self-center">
                  <p className="my-3">
                    <img
                      src="/assets/css/theme/sarochabackstage/img/sponsor.png"
                      className="mx-auto"
                      height="64"
                      alt=""
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
