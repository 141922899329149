import React, { useEffect } from "react";
import $ from "jquery";
import "./App.css";

export default function Limit() {

  useEffect(() => {
    $('html').addClass('h-100');
  }, [])

  return (
    <>
      <link rel="stylesheet" href="/assets/live-stream/css/main.css"></link>
      <div style={{ height: '100%' }}>
        <main className="main-container h-100 py-3" roles="main">
          <div className="container h-100">
            <div className="row h-100">
              <div className="col-12 align-self-start align-self-lg-center">
                <div className="row justify-content-center">
                  <div className="col-12 col-md-3">
                    <p className="text-center">
                      <img
                        className="mx-auto"
                        src="https://www.thaiticketmajor.com/assets/img/sorry.svg"
                        alt=""
                      />
                    </p>
                  </div>
                  <div className="col-12 col-md-6 align-self-center">
                    <div className="text-center text-md-left" style={{ color: '#FFF' }}>
                      <p className="txtBigger">
                        {/* <strong>The connection was reset. Please re-enter code.</strong> */}
                        <strong>Your account is in use on another device.</strong>
                      </p>
                      <p className="txtMed">
                        If you've shared your account with friends or family, they
                        may be using TTM Live while you're trying to watch a live
                        streaming. To solve this problem, make sure that no one
                        else is using your account before trying to stream again.
                    </p>
                      {/* <p>
                        <Link to={`/${query.get("e")}/code`} style={{ color: '#FFF', textDecoration: 'underline', fontSize: '22px' }}>Back to enter code</Link>
                      </p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
