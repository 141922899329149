import React from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";

import Limit from "./limit";
import Denied from "./denied";
import Notfound from "./notfound";
// import Test from "./test";
import AppProvider from "./AppProvider";
import * as serviceWorker from "./serviceWorker";
// import AppCodeEarthmix from "./earthmix/AppCode";
// import AppCodeChaeunwoo from "./cha-eun-woo/AppCode";
// import AppCodeHKT48_VIP from './hkt48_vip/AppCode';
// import AppCodeHKT48 from "./hkt48/AppCode";
// import AppCodeHan from "./hansuengwoo/AppCode";
// import AppCodeTonHon from './tonhon/AppCode';
// import AppCodeBF from './myboyfriends/AppCode';
// import AppCodeMew from './mewinjoseon/AppCode';
// import AppCodeBounPrem from './bounprem/AppCode';
// import AppCodeFishuponthesky from './fishuponthesky/AppCode';
// import AppCodeBamBam from './bambam/AppCode.js';
// import AppCodeTT from './tharntypewedding/AppCode';
// import AppCodeLancome from './lancome/AppCode';
// import Feedback from './feedback';
// import AppCodeLancome from './lancome/AppCode';
// import AppCodeMewbday from './mewbday/AppCode';
// import AppCodeDMDLand from './dmdland/AppCode';
// import AppCodePolca from './polca/AppCode';
// import AppCodeNunew from './nunew/AppCode';
// import AppCodeShootingstar from './shooting-star/AppCode';
// import AppCodeKinnporsche from './kinnporsche/AppCode';
// import AppCodeCaptureHappiness from './lancome-capturehappiness/AppCode';
// import AppCodeYoungjae from './youngjae/AppCode';
// import AppCodeOnfriendcity from './onfriendcity/AppCode';
// import AppCodeLoveoutloud from './loveoutloud/AppCode';
// import AppCodeZeepruk from './zee-pruk/AppCode';
// import AppCodeLancomeHappinessChapter from './lancomehappinesschapter/AppCode';
// import AppCodeFeelFanFun from './feelfanfun/AppCode';
// import AppCodeSeasonoflove from './seasonoflove/AppCode';
// import AppCodeSidebysidebrightwin from './sidebysidebrightwin/AppCode';
// import AppCodeCutiepie_ep1 from './cutiepie_ep1/AppCode';
// import AppCodeThedebutante from './thedebutante/AppCode';
// import AppCodeCutielovefanconcert from './cutielovefanconcert/AppCode';
// import AppCodeMyschoolpresident from './myschoolpresident/AppCode';
// import AppCodeMyschoolpresident2 from './myschoolpresident2/AppCode';
// import AppCodeFreenbecky from './freenbecky/AppCode';
// import AppCodeBeluca from './beluca/AppCode';
// import AppCodeOrntara from './orntara/AppCode';
// import AppCodeThereborn from './thereborn/AppCode';
// import AppCodelol2023_round1 from './lol2023_round1/AppCode';
// import AppCodelol2023_round2 from './lol2023_round2/AppCode';
// import AppCodesarocha_round1 from './sarocha_1/AppCode';
// import AppCodesarocha_round2 from './sarocha_2/AppCode';
// import AppCodeGemini_round1 from './gemini_round1/AppCode';
// import AppCodeGemini_round2 from './gemini_round2/AppCode';
// import AppCodeFanday_round1 from './fanday_round1/AppCode';
// import AppCodeFanday_round2 from './fanday_round2/AppCode';
// import AppCodeFanday_round3 from './fanday_round3/AppCode';
// import AppCodeFanday_round4 from './fanday_round4/AppCode';
// import AppCodeFanday_round5 from './fanday_round5/AppCode';
// import AppCodeSpecialstage from './specialstage/AppCode';
// import AppCodeFreenbecky_jp from './freenbecky_jp/AppCode';
// import AppCodeTheKrist1 from './thekrist1/AppCode';
// import AppCodeTheKrist2 from './thekrist2/AppCode';
// import AppCodeStarlympic from './starlympic/AppCode';
// import AppCodeHikingBecky from './hikingbecky/AppCode';
// import AppCodeDMDLand2 from './dmdland2/AppCode';
// import AppCodeDMDLand2_onsale from './dmdland2_onsale/AppCode';
// import AppCodeThesign from './thesign/AppCode';
// import AppCodeWinholidate from './winholidate/AppCode';
// import AppCodeNanon from './nanon/AppCode';
// import AppCodeLasttwilight from './lasttwilight/AppCode';
// import AppCodeThesignlostinthejungle from './thesignlostinthejungle/AppCode';
// import AppCodeThesignInJapan_rd1 from './thesigninjapan_rd1/AppCode';
// import AppCodeThesignInJapan_rd2 from './thesigninjapan_rd2/AppCode';
// import AppCodeBabii from './babii/AppCode';
// import AppCodePolca from './polca/AppCode';
// import AppCodeLoveoutloud_Rd1 from './loveoutloud2024_round1/AppCode';
// import AppCodeLoveoutloud_Rd2 from './loveoutloud2024_round2/AppCode';
// import AppCodeThesignencore from './thesignencore/AppCode';
// import AppCodeAuthenticmile from './authenticmile/AppCode';
// import AppCodePeraya from './peraya/AppCode';
// import AppCodeBilly from './billy/AppCode';
// import AppBlanktheseries from './blanktheseries/AppCode';
// import AppCodeBouncy from './bouncy/AppCode';
// import AppCodeTheloyalpin from './theloyalpin/AppCode';
// import AppCodeFinaldream from './finaldream/AppCode';
import AppCodeWeAreForeverFanconRoundOne from './weareforeverfancon_round1/AppCode';
import AppCodeWeAreForeverFanconRoundTwo from './weareforeverfancon_round2/AppCode';

import AppCodePoohPavelFirstDate from './poohpavelfirstdate/AppCode';
import AppCodeSarochaBackStage from './sarochabackstage/AppCode';
import AppCodeTheFreenairyOracle from './thefreenairyoracle/AppCode';
import AppCodeYokoBirthdayParty from './yokobirthdayparty/AppCode';
import AppGeminiFourthRunTheWorldConcert from './geminifourthruntheworldconcert/AppCode';
ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Switch>
        {/* PREVIEW */}
        {/* <Route path={["/feedback"]} exact children={<Feedback />} /> */}

        {/* REMOVE TRAILING SLASH */}
        <Redirect from="/:url*(/+)" to={window.location.pathname.slice(0, -1)} />

        {/* REDIRECT TO TTM */}
        <Route path={["/"]} exact render={() => (window.location = "https://www.thaiticketmajor.com/index.php")} />

        {/* YOUNGJAE */}
        {/* <Route path='/youngjae_colors_online_fan_meeting' component={() => { window.location = 'https://live.thaiticketmajor.com/youngjae_colors/endshow'; return null; }} /> */}
        {/* <Route path={["/youngjae_colors_online_fan_meeting/"]} exact children={<AppProvider eventid={'464'} />} />
        <Route path={["/youngjae_colors_online_fan_meeting/code"]} exact children={<AppCodeYoungjae eventid={'464'} />} /> */}

        {/* earthmix earthmixlovelivefanmeet */}
        {/* <Route path={["/earthmixlovelivefanmeet"]} exact children={<AppProvider eventid={'413'} />} />
        <Route path={["/earthmixlovelivefanmeet/code"]} exact children={<AppCodeEarthmix eventid={'413'} />} /> */}

        {/* chaeunwoo just one in to you chaeunwoojustoneintoyou */}
        {/* <Route path={["/chaeunwoojustoneintoyou"]} exact children={<AppProvider eventid={'420'} />} />
        <Route path={["/chaeunwoojustoneintoyou/code"]} exact children={<AppCodeChaeunwoo eventid={'420'} />} /> */}

        {/* <Route path={["/meetingfishuponthesky/"]} exact children={<AppProvider eventid={'432'} />} />
        <Route path={["/meetingfishuponthesky/code"]} exact children={<AppCodeFishuponthesky eventid={'432'} />} /> */}

        {/* <Route path={["/bambamfirstribbon/"]} exact children={<AppProvider eventid={'436'} />} />
        <Route path={["/bambamfirstribbon/code"]} exact children={<AppCodeBamBam eventid={'436'} />} /> */}

        {/* HKT48  hkt48letsgotohakata */}
        {/* <Route path={["/hkt48letsgotohakata"]} exact children={<AppProvider eventid={'414'} />} />
        <Route path={["/hkt48letsgotohakata/code"]} exact children={<AppCodeHKT48 eventid={'414'} />} />
        <Route path={["/hkt48special"]} exact children={<AppProvider eventid={'005'} />} />
        <Route path={["/hkt48special/code"]} exact children={<AppCodeHKT48_VIP eventid={'005'} />} /> */}

        {/* TONHON  */}
        {/* <Route path={["/tonhonchonlatee"]} exact children={<AppProvider eventid={'365'} />} />
        <Route path={["/tonhonchonlatee/code"]} exact children={<AppCodeTonHon eventid={'365'} />} /> */}

        {/* MEW IN JOSEON  */}
        {/* <Route path={["/mewrerun/"]} exact children={<AppProvider eventid={'003'} />} />
        <Route path={["/mewrerun/code"]} exact children={<AppCodeMew eventid={'003'} />} /> */}

        {/* <Route path={["/bounpremisreal"]} exact children={<AppProvider eventid={'381'} />} />
        <Route path={["/bounpremisreal/code"]} exact children={<AppCodeBounPrem eventid={'381'} />} /> */}

        {/* MY BOY FRIENDS  */}
        {/* <Route path={["/myboyfriends/", "/myboyfriends/code"]} exact render={() => (window.location = "https://www.thaiticketmajor.com/live-stream/rerun-bemyboyfriends.html")} /> */}
        {/* <Route path={["/myboyfriends/"]} exact children={<AppProvider eventid={305} />} />
        <Route path={["/myboyfriends/code"]} exact children={<AppCodeBF eventid={305} />} /> */}

        {/* <Route path={["/tharntypewedding/"]} exact children={<AppProvider eventid={361} />} />
        <Route path={["/tharntypewedding/code"]} exact children={<AppCodeTT eventid={361} />} /> */}
        {/* <Route path={["/tharntypewedding/", "/tharntypewedding/code"]} exact render={() => (window.location = "https://www.thaiticketmajor.com/live-stream/tharntype-wedding-day-comingsoon.php")} /> */}

        {/* CLOSED BY AEH DEAW */}
        {/* <Route path={["/lancomekristsingto"]} exact children={<AppProvider eventid={'002'} />} />
        <Route path={["/lancomekristsingto/code"]} exact children={<AppCodeLancome eventid={'002'} />} /> */}

        {/* HAN SEUNG WOO 1st ONTACT STAGE */}
        {/* <Route path={["/hanseungwoo/"]} exact children={<AppProvider eventid={970} />} />
        <Route path={["/hanseungwoo/code"]} exact children={<AppCodeHan eventid={970} />} /> */}

        {/*Lancome happiness*/}
        {/* <Route path={["/lancome"]} exact children={<AppProvider eventid={'015'} />} />
        <Route path={["/lancome/code"]} exact children={<AppCodeLancome eventid={'015'} />} /> */}

        {/* mew birthday */}
        {/* <Route path={["/mewbday/"]} exact children={<AppProvider eventid={456} />} />
        <Route path={["/mewbday/code"]} exact children={<AppCodeMewbday eventid={456} />} /> */}

        {/* lancome-capturehappiness */}
        {/* <Route path={["/lancome_capturehappiness/"]} exact children={<AppProvider eventid={'016'} />} />
        <Route path={["/lancome_capturehappiness/code"]} exact children={<AppCodeCaptureHappiness eventid={'016'} />} /> */}

        {/* lancome-capturehappiness */}
        {/* <Route path={["/lancome_happiness/"]} exact children={<AppProvider eventid={'017'} />} />
        <Route path={["/lancome_happiness/code"]} exact children={<AppCodeCaptureHappiness eventid={'017'} />} /> */}

        {/* dmdland */}
        {/* <Route path={["/dmdland/"]} exact children={<AppProvider eventid={569} />} />
        <Route path={["/dmdland/code"]} exact children={<AppCodeDMDLand eventid={569} />} /> */}

        {/* polca */}
        {/* <Route path={["/polca-the-journey/"]} exact children={<AppProvider eventid={565} />} />
        <Route path={["/polca-the-journey/code"]} exact children={<AppCodePolca eventid={565} />} /> */}

        {/* nunew */}
        {/* <Route path={["/nunew-fanmeet-rerun/"]} exact children={<AppProvider eventid={'018'} />} />
        <Route path={["/nunew-fanmeet-rerun/code"]} exact children={<AppCodeNunew eventid={'018'} />} /> */}

        {/* shooting-star */}
        {/* <Route path={["/shooting-star/"]} exact children={<AppProvider eventid={'577'} />} />
        <Route path={["/shooting-star/code"]} exact children={<AppCodeShootingstar eventid={'577'} />} /> */}

        {/* kinnporsche */}
        {/* <Route path={["/kinnporsche-rerun/"]} exact children={<AppProvider eventid={'623'} />} />
        <Route path={["/kinnporsche-rerun/code"]} exact children={<AppCodeKinnporsche eventid={'623'} />} /> */}

        {/* kinnporsche */}
        {/* <Route path={["/kinnporsche2-rerun/"]} exact children={<AppProvider eventid={'019'} />} />
        <Route path={["/kinnporsche2-rerun/code"]} exact children={<AppCodeKinnporsche eventid={'019'} />} /> */}

        {/* onfriendcity */}
        {/* <Route path={["/onfriendcity/"]} exact children={<AppProvider eventid={'600'} />} />
        <Route path={["/onfriendcity/code"]} exact children={<AppCodeOnfriendcity eventid={'600'} />} /> */}

        {/* loveoutloud */}
        {/* <Route path={["/loveoutloud/"]} exact children={<AppProvider eventid={'613'} />} />
        <Route path={["/loveoutloud/code"]} exact children={<AppCodeLoveoutloud eventid={'613'} />} /> */}

        {/* zee-pruk */}
        {/* <Route path={["/zeepruk/"]} exact children={<AppProvider eventid={'677'} />} />
        <Route path={["/zeepruk/code"]} exact children={<AppCodeZeepruk eventid={'677'} />} /> */}

        {/* lancome happiness chapter */}
        {/* <Route path={["/lancome-happiness-chapter/"]} exact children={<AppProvider eventid={'020'} />} />
        <Route path={["/lancome-happiness-chapter/code"]} exact children={<AppCodeLancomeHappinessChapter eventid={'020'} />} /> */}

        {/* feel fan fun */}
        {/* <Route path={["/feel-fan-fun/"]} exact children={<AppProvider eventid={'697'} />} />
        <Route path={["/feel-fan-fun/code"]} exact children={<AppCodeFeelFanFun eventid={'697'} />} /> */}

        {/* Season of love */}
        {/* <Route path={["/seasons-of-love/"]} exact children={<AppProvider eventid={'831'} />} />
        <Route path={["/seasons-of-love/code"]} exact children={<AppCodeSeasonoflove eventid={'831'} />} /> */}

        {/* Season of love */}
        {/* <Route path={["/sidebysidebrightwin/"]} exact children={<AppProvider eventid={'747'} />} />
        <Route path={["/sidebysidebrightwin/code"]} exact children={<AppCodeSidebysidebrightwin eventid={'747'} />} /> */}

        {/* Cutie pie ep4 */}
        {/* <Route path={["/cutiepie_ep4/"]} exact children={<AppProvider eventid={'867'} />} />
        <Route path={["/cutiepie_ep4/code"]} exact children={<AppCodeCutiepie_ep1 eventid={'867'} />} /> */}

        {/* gap-the-butante */}
        {/* <Route path={["/gap-the-debutante/"]} exact children={<AppProvider eventid={'898'} />} />
        <Route path={["/gap-the-debutante/code"]} exact children={<AppCodeThedebutante eventid={'898'} />} /> */}

        {/* Cutie love fan concert */}
        {/* <Route path={["/cutielovefanconcert/"]} exact children={<AppProvider eventid={'902'} />} />
        <Route path={["/cutielovefanconcert/code"]} exact children={<AppCodeCutielovefanconcert eventid={'902'} />} /> */}

        {/* My school round 1 */}
        {/* <Route path={["/myschoolpresident_round1/"]} exact children={<AppProvider eventid={'886'} />} />
        <Route path={["/myschoolpresident_round1/code"]} exact children={<AppCodeMyschoolpresident eventid={'886'} />} /> */}

        {/* My school round 2 */}
        {/* <Route path={["/myschoolpresident_round2/"]} exact children={<AppProvider eventid={'023'} />} />
        <Route path={["/myschoolpresident_round2/code"]} exact children={<AppCodeMyschoolpresident2 eventid={'023'} />} /> */}

        {/* freenbecky 2 */}
        {/* <Route path={["/freenbecky/"]} exact children={<AppProvider eventid={'949'} />} />
        <Route path={["/freenbecky/code"]} exact children={<AppCodeFreenbecky eventid={'949'} />} /> */}

        {/* beluca */}
        {/* <Route path={["/beluca/"]} exact children={<AppProvider eventid={'970'} />} />
        <Route path={["/beluca/code"]} exact children={<AppCodeBeluca eventid={'970'} />} /> */}

        {/* orntara */}
        {/* <Route path={["/orntara/"]} exact children={<AppProvider eventid={'024'} />} />
        <Route path={["/orntara/code"]} exact children={<AppCodeOrntara eventid={'024'} />} /> */}

        {/* thereborn */}
        {/* <Route path={["/rebornfirstsolo/"]} exact children={<AppProvider eventid={'025'} />} />
        <Route path={["/rebornfirstsolo/code"]} exact children={<AppCodeThereborn eventid={'025'} />} /> */}

        {/* lol2023 Rd1 */}
        {/* <Route path={["/lol2023_round1/"]} exact children={<AppProvider eventid={'423_1'} />} />
        <Route path={["/lol2023_round1/code"]} exact children={<AppCodelol2023_round1 eventid={'423_1'} />} /> */}

        {/* lol2023 Rd2 */}
        {/* <Route path={["/lol2023_round2/"]} exact children={<AppProvider eventid={'423_2'} />} />
        <Route path={["/lol2023_round2/code"]} exact children={<AppCodelol2023_round2 eventid={'423_2'} />} /> */}

        {/* sarocha Rd1 */}
        {/* <Route path={["/sarochasolostage_round1/"]} exact children={<AppProvider eventid={'552_1'} />} />
        <Route path={["/sarochasolostage_round1/code"]} exact children={<AppCodesarocha_round2 eventid={'552_1'} />} /> */}

        {/* sarocha Rd1 */}
        {/* <Route path={["/sarochasolostage_round2/"]} exact children={<AppProvider eventid={'552_2'} />} />
        <Route path={["/sarochasolostage_round2/code"]} exact children={<AppCodesarocha_round2 eventid={'552_2'} />} /> */}

        {/* sarocha Rd1 */}
        {/* <Route path={["/sarochasolostage_round1/"]} exact children={<AppProvider eventid={'552_1'} />} />
        <Route path={["/sarochasolostage_round1/code"]} exact children={<AppCodesarocha_round2 eventid={'552_1'} />} /> */}

        {/* gemini Rd1 */}
        {/* <Route path={["/gemini_round1/"]} exact children={<AppProvider eventid={'514_1'} />} />
        <Route path={["/gemini_round1/code"]} exact children={<AppCodeGemini_round1 eventid={'514_1'} />} /> */}

        {/* gemini Rd2 */}
        {/* <Route path={["/gemini_round2/"]} exact children={<AppProvider eventid={'514_2'} />} />
        <Route path={["/gemini_round2/code"]} exact children={<AppCodeGemini_round2 eventid={'514_2'} />} /> */}

        {/* fanday Rd1 */}
        {/* <Route path={["/fanday_round1/"]} exact children={<AppProvider eventid={'578_1'} />} />
        <Route path={["/fanday_round1/code"]} exact children={<AppCodeFanday_round1 eventid={'578_1'} />} /> */}

        {/* fanday Rd2 */}
        {/* <Route path={["/fanday_round2/"]} exact children={<AppProvider eventid={'578_2'} />} />
        <Route path={["/fanday_round2/code"]} exact children={<AppCodeFanday_round2 eventid={'578_2'} />} /> */}

        {/* fanday Rd3 */}
        {/* <Route path={["/fanday_round3/"]} exact children={<AppProvider eventid={'578_3'} />} />
        <Route path={["/fanday_round3/code"]} exact children={<AppCodeFanday_round3 eventid={'578_3'} />} /> */}

        {/* fanday Rd4 */}
        {/* <Route path={["/fanday_round4/"]} exact children={<AppProvider eventid={'578_4'} />} />
        <Route path={["/fanday_round4/code"]} exact children={<AppCodeFanday_round4 eventid={'578_4'} />} /> */}

        {/* fanday Rd5 */}
        {/* <Route path={["/fanday_round5/"]} exact children={<AppProvider eventid={'578_5'} />} />
        <Route path={["/fanday_round5/code"]} exact children={<AppCodeFanday_round5 eventid={'578_5'} />} /> */}

        {/* 789 special stage */}
        {/* <Route path={["/789specialstage/"]} exact children={<AppProvider eventid={'560'} />} />
        <Route path={["/789specialstage/code"]} exact children={<AppCodeSpecialstage eventid={'560'} />} /> */}

        {/* freen becky in japan */}
        {/* <Route path={["/freenbecky-japan/"]} exact children={<AppProvider eventid={'642'} />} />
        <Route path={["/freenbecky-japan/code"]} exact children={<AppCodeFreenbecky_jp eventid={'642'} />} /> */}

        {/* the krist */}
        {/* <Route path={["/krist_round1/"]} exact children={<AppProvider eventid={'601_1'} />} />
        <Route path={["/krist_round1/code"]} exact children={<AppCodeTheKrist1 eventid={'601_1'} />} /> */}

        {/* the krist */}
        {/* <Route path={["/krist_round2/"]} exact children={<AppProvider eventid={'601_2'} />} />
        <Route path={["/krist_round2/code"]} exact children={<AppCodeTheKrist2 eventid={'601_2'} />} /> */}

        {/* starlympic */}
        {/* <Route path={["/starlympic2023/"]} exact children={<AppProvider eventid={'683'} />} />
        <Route path={["/starlympic2023/code"]} exact children={<AppCodeStarlympic eventid={'683'} />} /> */}

        {/* hikingbecky */}
        {/* <Route path={["/hikingbecky/"]} exact children={<AppProvider eventid={'705'} />} />
        <Route path={["/hikingbecky/code"]} exact children={<AppCodeHikingBecky eventid={'705'} />} /> */}

        {/* dmdland2 */}
        {/* <Route path={["/dmdland2/"]} exact children={<AppProvider eventid={'733'} />} />
        <Route path={["/dmdland2/code"]} exact children={<AppCodeDMDLand2 eventid={'733'} />} /> */}

        {/* dmdland2 on sale*/}
        {/* <Route path={["/rerundmdland2/"]} exact children={<AppProvider eventid={'779'} />} />
        <Route path={["/rerundmdland2/code"]} exact children={<AppCodeDMDLand2_onsale eventid={'779'} />} /> */}

        {/* thesign*/}
        {/* <Route path={["/thesignfinalepisode/"]} exact children={<AppProvider eventid={'766'} />} />
        <Route path={["/thesignfinalepisode/code"]} exact children={<AppCodeThesign eventid={'766'} />} /> */}

        {/* win-holidate*/}
        {/* <Route path={["/win-holidate/"]} exact children={<AppProvider eventid={'743'} />} />
        <Route path={["/win-holidate/code"]} exact children={<AppCodeWinholidate eventid={'743'} />} /> */}

        {/* nanonborntobeoconcert */}
        {/* <Route path={["/nanonborntobeoconcert-rerun/"]} exact children={<AppProvider eventid={'777'} />} />
        <Route path={["/nanonborntobeoconcert-rerun/code"]} exact children={<AppCodeNanon eventid={'777'} />} /> */}

        {/* lasttwilightonstage */}
        {/* <Route path={["/lasttwilightonstage-rerun/"]} exact children={<AppProvider eventid={'823'} />} />
        <Route path={["/lasttwilightonstage-rerun/code"]} exact children={<AppCodeLasttwilight eventid={'823'} />} /> */}

        {/* lostinthejungle */}
        {/* <Route path={["/lost-in-the-jungle-rerun/"]} exact children={<AppProvider eventid={'859'} />} />
        <Route path={["/lost-in-the-jungle-rerun/code"]} exact children={<AppCodeThesignlostinthejungle eventid={'859'} />} /> */}

        {/* thesign in japan round 1 */}
        {/* <Route path={["/walk-by-the-river-rerun-round1/"]} exact children={<AppProvider eventid={'897_1'} />} />
        <Route path={["/walk-by-the-river-rerun-round1/code"]} exact children={<AppCodeThesignInJapan_rd1 eventid={'897_1'} />} /> */}

        {/* thesign in japan round 2 */}
        {/* <Route path={["/walk-by-the-river-rerun-round2/"]} exact children={<AppProvider eventid={'897_2'} />} />
        <Route path={["/walk-by-the-river-rerun-round2/code"]} exact children={<AppCodeThesignInJapan_rd2 eventid={'897_2'} />} /> */}

        {/* babii 24/7 */}
        {/* <Route path={["/babii247concert-rerun/"]} exact children={<AppProvider eventid={'791'} />} />
        <Route path={["/babii247concert-rerun/code"]} exact children={<AppCodeBabii eventid={'791'} />} /> */}

        {/* polca */}
        {/* <Route path={["/polcatimetravelingcon-rerun/"]} exact children={<AppProvider eventid={'026'} />} />
        <Route path={["/polcatimetravelingcon-rerun/code"]} exact children={<AppCodePolca eventid={'026'} />} /> */}

        {/* <Route path={["/loveoutloud2024_round1/"]} exact children={<AppProvider eventid={'883_1'} />} />
        <Route path={["/loveoutloud2024_round1/code"]} exact children={<AppCodeLoveoutloud_Rd1 eventid={'883_1'} />} />

        <Route path={["/loveoutloud2024_round2/"]} exact children={<AppProvider eventid={'883_2'} />} />
        <Route path={["/loveoutloud2024_round2/code"]} exact children={<AppCodeLoveoutloud_Rd2 eventid={'883_2'} />} /> */}

        {/* <Route path={["/thesignencore/"]} exact children={<AppProvider eventid={'989'} />} />
        <Route path={["/thesignencore/code"]} exact children={<AppCodeThesignencore eventid={'989'} />} /> */}

        {/* <Route path={["/authenticmile/"]} exact children={<AppProvider eventid={'986'} />} />
        <Route path={["/authenticmile/code"]} exact children={<AppCodeAuthenticmile eventid={'986'} />} /> */}

        {/* <Route path={["/peraya/"]} exact children={<AppProvider eventid={'933'} />} />
        <Route path={["/peraya/code"]} exact children={<AppCodePeraya eventid={'933'} />} /> */}

        {/* <Route path={["/billy/"]} exact children={<AppProvider eventid={'029'} />} />
        <Route path={["/billy/code"]} exact children={<AppCodeBilly eventid={'029'} />} /> */}

        {/* <Route path={["/authenticmile/"]} exact children={<AppProvider eventid={'030'} />} />
        <Route path={["/authenticmile/code"]} exact children={<AppCodeAuthenticmile eventid={'030'} />} /> */}

        {/* <Route path={["/blanktheseries-2/"]} exact children={<AppProvider eventid={'031'} />} />
        <Route path={["/blanktheseries-2/code"]} exact children={<AppBlanktheseries eventid={'031'} />} /> */}

        {/* <Route path={["/the-final-dream/"]} exact children={<AppProvider eventid={'034'} />} />
        <Route path={["/the-final-dream/code"]} exact children={<AppCodeFinaldream eventid={'034'} />} /> */}

        {/* <Route path={["/bouncy-boun/"]} exact children={<AppProvider eventid={'032'} />} />
        <Route path={["/bouncy-boun/code"]} exact children={<AppCodeBouncy eventid={'032'} />} /> */}

        {/* <Route path={["/the-loyal-pin/"]} exact children={<AppProvider eventid={'033'} />} />
        <Route path={["/the-loyal-pin/code"]} exact children={<AppCodeTheloyalpin eventid={'033'} />} /> */}

        <Route path={["/weareforeverfancon_round1"]} exact children={<AppProvider eventid={'035_1'} />} />
        <Route path={["/weareforeverfancon_round1/code"]} exact children={<AppCodeWeAreForeverFanconRoundOne eventid={'035_1'} />} />

        <Route path={["/weareforeverfancon_round2"]} exact children={<AppProvider eventid={'035_2'} />} />
        <Route path={["/weareforeverfancon_round2/code"]} exact children={<AppCodeWeAreForeverFanconRoundTwo eventid={'035_2'} />} />

        <Route path={["/poohpavelfirstdate"]} exact children={<AppProvider eventid={'036'} />} />
        <Route path={["/poohpavelfirstdate/code"]} exact children={<AppCodePoohPavelFirstDate eventid={'036'} />} />

        <Route path={["/sarochabackstage"]} exact children={<AppProvider eventid={'037'} />} />
        <Route path={["/sarochabackstage/code"]} exact children={<AppCodeSarochaBackStage eventid={'037'} />} />

        <Route path={["/thefreenairyoracle"]} exact children={<AppProvider eventid={'038'} />} />
        <Route path={["/thefreenairyoracle/code"]} exact children={<AppCodeTheFreenairyOracle eventid={'038'} />} />

        <Route path={["/yokobirthdayparty"]} exact children={<AppProvider eventid={'039'} />} />
        <Route path={["/yokobirthdayparty/code"]} exact children={<AppCodeYokoBirthdayParty eventid={'039'} />} />

        <Route path={["/geminifourthruntheworldconcert"]} exact children={<AppProvider eventid={'040'} />} />
        <Route path={["/geminifourthruntheworldconcert/code"]} exact children={<AppGeminiFourthRunTheWorldConcert eventid={'040'} />} />

        {/* CUSTOMED PAGE */}
        <Route path={["/limitaccess"]} children={<Limit />} />
        <Route path={["/accessdenied"]} children={<Denied />} />
        {/* <Route path={["/test"]} exact children={<Test />} /> */}
        <Route path="*">
          <Notfound />
        </Route>
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();