import { useEffect, useState } from 'react';

const useOutsideClick = (ref) => {
    
    const [clickOutside, setClickOutside] = useState(true);

    useEffect(() => {
      
        document.addEventListener('click', handleClick);
        
        return () => document.removeEventListener('click', handleClick);
      
    }, []);
    
    const handleClick = (e) => {
        
        ref.current?.contains(e.target)
        ? setClickOutside(false)
        : setClickOutside(true);
        // if(ref.current?.contains(e.target)) {
        //     if(!clickOutside) {
        //         setClickOutside(true);
        //     }            
        // } else {
        //     setClickOutside(true);
        // }        
    };
    
    return clickOutside;
    
};

export default useOutsideClick;