import React, { useState, createContext } from 'react'

export const StoreContext = createContext({});

export const StoreContextProvider = ({ children }) => {

    const [ wsMessage, setWSMessage ] = useState('');
    const [ sendMessage, setMessage ] = useState('');

    const store = { 
        websocketStore : [wsMessage, setWSMessage],
        messageStore : [sendMessage, setMessage]
    }

    return <StoreContext.Provider value={store}>{ children }</StoreContext.Provider>
}