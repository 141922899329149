import React, { useEffect } from "react";
import { Link } from 'react-router-dom';
import $ from "jquery";
import "./App.css";

export default function Notfound() {

  useEffect(() => {
    $('html').addClass('h-100');
  }, [])

  return (
    <>
    <link rel="stylesheet" href="/assets/live-stream/css/main.css"></link>
    <div style={{ height: '100%'}}>
      <main className="main-container h-100 py-3" roles="main">
        <div className="container h-100">
          <div className="row h-100">
            <div className="col-12 align-self-start align-self-lg-center">
              <div className="row justify-content-center">
                <div className="col-12 col-md-3">
                  <p className="text-center">
                    <img
                      className="mx-auto"
                      src="https://www.thaiticketmajor.com/assets/img/search-not-found.svg"
                      alt=""
                    />
                  </p>
                </div>
                <div className="col-12 col-md-6 align-self-center">
                  <div className="text-center text-md-left" style={{ color: '#FFF'}}>
                    <p className="txtBigger" style={{ fontSize: '24px'}}>
                      <strong>Page Not Found</strong>
                    </p>
                    <p className="txtMed">
                        Sorry, we can't find what you're looking for.
                    </p>                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
    </>
  );
}
