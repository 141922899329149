import React, { useState, useEffect } from "react";
import moment from 'moment';
import axios from "axios";
import { ByteArkV2UrlSigner } from 'byteark-sdk';
import { ByteArkPlayerContainer } from 'byteark-player-react';
import config from "../config";

export default function Player({ event, subtitleCode }) {

  const [streamURL, setStreamURL] = useState(null);
  const [playerOptions, setPlayerOptions] = useState(null);

  useEffect(() => {
    getStreamUrl();
  }, [subtitleCode]);

  useEffect(() => {

    if (streamURL !== null) {
      // const signer = new ByteArkV2UrlSigner({
      //   access_id: 'fleet-1631',
      //   access_secret: 'bf3a4383712548785079615d60383738',
      // });
      const signer = new ByteArkV2UrlSigner({
        access_id: 'key-SsIrhZrAbgSeyvuA',
        access_secret: 'SsIrhZvnH7VnBb8P8mBys9mptSmYcoLTLMjBRchC',
      });

      // const pathPrefix = getPathPrefixBySubTitle(subtitleCode);

      const stream_expired_time = moment().add(4, 'hours').format('YYYY-MM-DDTHH:mm:ss');      
      const stream_expired_unix_timestamp = moment(stream_expired_time).format('X');

      // const signedUrl = signer.sign(
      //   streamURL,
      //   parseInt(stream_expired_unix_timestamp),
      //   {
      //     path_prefix: pathPrefix
      //   }
      // );
      
      const signedUrl = signer.sign(streamURL, parseInt(stream_expired_unix_timestamp), {method : 'GET'});
      

      const _options = {
        autoplay: true,
        fluid: true,
        aspectRatio: '16:9',
        sources: {
          src: signedUrl,
          type: 'application/x-mpegURL',
          title: event.name
        }
      }

      setPlayerOptions(_options);
    }
  }, [streamURL]);

  const getStreamUrl = async () => {

    const url = `${config.AWS_LIVE_API_HOST}${config.AWS_API_ENDPOINT.GET_STREAMS}rerun_weareforeverfancon_round1_${subtitleCode.toLowerCase()}`;
    // console.log(url);
    try {
      const res = await axios.get(url);
      if (res.status === 200) {
        setStreamURL(res.data.url);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  // const getPathPrefixBySubTitle = _subcode => {
  //   if (_subcode.toUpperCase() === 'EN') {
  //     return "/streams/SOucxIWMJxW/";
  //   } else if (_subcode.toUpperCase() === 'CN') {
  //     return "/streams/SOudGfhMM8vU/"; 
  //   } else {
  //     return "/streams/SsJdyJraIvU7/";
  //   }
  // }

  return (
    <>
    {/* <div className="player"><img className="w-100" src="/assets/css/theme/bounprem/img/1920x1080.jpg" alt="" /></div> */}
      <div className="player">
        <ByteArkPlayerContainer {...playerOptions} />
      </div>
    </>
  );
}