module.exports = {   
    WSS_LINK: "wss://pmn07qznu8.execute-api.ap-southeast-1.amazonaws.com/dev?id={id}&eventid={eventid}",
    WSS_LINK_SYSTEM: "wss://pmn07qznu8.execute-api.ap-southeast-1.amazonaws.com/dev?id=",
    JWT_TOKEN_SECRETKEY: "ed7ca5b6947086968dd793ee7ac7b00f",
    JWT_DATA_SECRETKEY: "77wEOidBNlIe4g69",
    JWT_STORAGE_SECRETKEY: "l;ylfu=k;F]d",    
    JWT_STORAGE_EXPIRED_MINUTES: 180,
    JWT_PAYMENT_SECRETKEY: "g8hdd]h;psv,",        
    AWS_LIVE_API_HOST: "https://k7971r4dlc.execute-api.ap-southeast-1.amazonaws.com/dev",
    AWS_LIVE_RERUN_HOST: "https://57t5g5zqz7.execute-api.ap-southeast-1.amazonaws.com/rerun",
    AWS_API_ENDPOINT: {
        GET_EVENTINFO: "/events/",       
        LOGIN_EXPIRED: "/login-expired",        
        WEBSOCKET_USERS: "/wss/users/",
        GET_STREAMS: '/events/streams/',       
        LOGS: '/logs',
        GET_TIMER: '/timer/{eventid}/{code}',
        UPDATE_TIMER: '/timer',
        FEEDBACK_REPORT: '/feedback-report'
    },
}