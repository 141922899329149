import axios from 'axios';
import moment from 'moment';
import config from "./config";

const insert = (eventid, publicip, logtype, message) => {

    const url = `${config.AWS_LIVE_API_HOST}${config.AWS_API_ENDPOINT.LOGS}`;
    const params = {
        eventid: eventid,
        created: moment().format(),
        logtype: logtype,
        ip: publicip,
        message: message
    }
    try {
        axios.post(url, params);        
    } catch (e) {
        console.log(e.message);
    }
}

export default { insert };