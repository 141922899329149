import React, { useState, useEffect, useContext } from "react";
import { Redirect, useLocation } from "react-router-dom";
import axios from "axios";
import jwt from "jsonwebtoken";
import config from "../config";
import WebSocket from "../components/websocket";
import { StoreContext } from "../context/store";
import Main from "./main";

function storageDecrypt(_token) {
  try {
    return jwt.verify(_token, config.JWT_STORAGE_SECRETKEY);
  } catch (err) {
    console.log(err.message);
    return null;
  }
}

export default function App({ eventid }) {
  const [user, setUser] = useState(null);
  const [event, setEvent] = useState(null);
  const [goEnterCode, setEnterCode] = useState(false);
  const [wsStatus, setWSStatus] = useState("");
  const { websocketStore } = useContext(StoreContext);
  const location = useLocation();

  useEffect(() => {
    if (user === null) {
      const storageToken = sessionStorage.getItem("_tkr" + eventid.toString());
      if (storageToken === null) {
        setEnterCode(true);
      } else {
        const User = storageDecrypt(storageToken);
        if (User === null) {
          setEnterCode(true);
        } else if (typeof User.urId === "undefined" || User.urId === "") {
          setEnterCode(true);
        } else {
          setUser(User);
        }
      }
    }
  }, [user]);

  useEffect(() => {
    if (event !== null) {
      document.title = event.name;
    } else {
      getEventInfo();
    }
  }, [event]);

  useEffect(() => {
    const ws_message = websocketStore[0];
    if (ws_message !== "") {
      if (ws_message === "AccessDenied") {
        // REJECT USER OUT OF STREAM        
        sessionStorage.removeItem("_tkr" + eventid.toString());
        setEnterCode(true);
      }
    }
  }, [websocketStore[0]]);

  const handleWSStatus = (_status) => {
    if (_status === "Open") {
      handleStorageWebSocketClientId();
    }
    setWSStatus(_status);
  };

  const handleStorageWebSocketClientId = async () => {
    const url =
      config.AWS_LIVE_API_HOST +
      config.AWS_API_ENDPOINT.WEBSOCKET_USERS +
      user.urId;
    try {
      const res = await axios.get(url);
      if (res.status === 200) {
        sessionStorage.setItem("_ws", res.data.connectionId);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  const getEventInfo = async () => {
    const url = `${config.AWS_LIVE_API_HOST}${config.AWS_API_ENDPOINT.GET_EVENTINFO}${eventid.toString()}`;
    try {
      const res = await axios.get(url);
      if (res.status === 200) {
        setEvent(res.data);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  return (
    <>
      { goEnterCode && <Redirect to={`/${location.pathname.split("/")[1]}/code`} />}
      { user !== null && <WebSocket user={user} eventid={'035_2'} getStatus={(_status) => { handleWSStatus(_status); }} />}
      { wsStatus === "Closed" && <></>}
      { wsStatus === "Open" && user !== null && event !== null && <Main event={event} user={user} />}
    </>
  );
}