import React, { useState, useEffect, useRef } from "react";
import Player from "./player";
import Merchandise from "./merchandise";
import useOutsideClick from "../components/useoutsideclick";
import Timer from "../components/timer";

export default function Main({ event, user }) {
  const [dropdownSubActive, setDropdownSubActive] = useState(false);
  const [subCode, setSubCode] = useState("link1");
  const [title, setTitle] = useState("");
  const dropdownRef = useRef();
  const outsideClick = useOutsideClick(dropdownRef);

  useEffect(() => {
    if (event !== null) {
      setTitle(event.name);
    }
  }, [event]);

  useEffect(() => {
    setDropdownSubActive(!outsideClick);
  }, [outsideClick]);

  console.log('---->',user)
  return (
    <>
      <link
        rel="stylesheet"
        href="/assets/css/bootstrap/bootstrap.min.css"
      ></link>
      <link rel="stylesheet" href="/assets/live-stream/css/main.css"></link>
      <link
        rel="stylesheet"
        href="/assets/css/theme/yokobirthdayparty/style/main.css"
      ></link>

      <div className="main">
        <main
          className="main-container ttm-live-stream"
          roles="main"
          id="watch"
        >
          <div className="container-fluid">
            <div className="d-none d-md-block">
              <div className="row justify-content-center">
                <div className="col-12 col-xl-8 col-xxl-9">
                  <div className="row justify-content-center align-items-center">
                    <div className="col-auto">
                      <p className="my-3">
                        <img
                          src="/assets/css/theme/yokobirthdayparty/img/logo-2.png"
                          height="64"
                          alt=""
                        />
                      </p>
                    </div>
                    <div className="col">
                      <h1 className="h4 align-middle my-2 text-main text-right">
                        <span className="align-middle d-inline-block">
                          Yoko Birthday Party ~ ENDLESS LOVE
                        </span>
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
          <div className="row">
            <div className="col-player">
              <div className="player-area">
                <div className="row justify-content-center">
                  <div className="col-12 col-xl-8 col-xxl-9">
                    <div className="player-container">
                      <div className="player">
                        <Player
                          event={event} subtitleCode={subCode}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row px-2 px-md-0 justify-content-center">
                <div className="col-auto">
                  {/* <div className="mt-2">
                    <button
                      type="button"
                      className={
                        subCode === "link1"
                          ? "btn-select-player btn btn-dark btn-sm"
                          : "btn-select-player btn btn-secondary btn-sm"
                      }
                      onClick={() => {
                        setSubCode("link1");
                      }}
                    >
                      &nbsp;Link 1&nbsp;
                    </button>
                    &nbsp;&nbsp;
                    <button
                      type="button"
                      className={
                        subCode === "link2"
                          ? "btn-select-player btn btn-dark btn-sm"
                          : "btn-select-player btn btn-secondary btn-sm"
                      }
                      onClick={() => {
                        setSubCode("link2");
                      }}
                    >
                      &nbsp;Link 2&nbsp;
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-5 col-lg-2">
              <p className="my-3 text-center">
                <img
                  src="/assets/css/theme/yokobirthdayparty/img/logo.png"
                  alt=""
                />
              </p>
            </div>
          </div>
        </div>
          <div class="row justify-content-center">&nbsp;</div>
          <div className="row">
            <div className="col-12 text-center">
              <div style={{ color: "#fff" }}>
                Remaining Time : <Timer eventid={user.ehId} code={user.urId} />
              </div>
            </div>
          </div>
          <div className="row">&nbsp;</div>
          <div className="row">
            <div className="col-12 text-center">
              <div style={{ color: "#fff" }}>
                กรณีต้องการหยุดรับชม ให้ท่านปิดแท็บ หากกด "หยุด"
                เวลาจะยังคงเดินต่อไป / To stop watching, please close the tab.
                Pressing "Pause" button will make the time continue counting
                down.
              </div>
            </div>
          </div>
          <div className="row">&nbsp;</div>
        </main>
      </div>
    </>
  );
}
