import React from "react";
import { StoreContextProvider } from "./context/store";

// import AppHan from "./hansuengwoo/App";
// import AppBF from './myboyfriends/App';
// import AppTT from './tharntypewedding/App';
// import AppLC from './lancome/App';
// import AppMew from './mewinjoseon/App';
// import AppBounPrem from './bounprem/App';
// import AppTonHon from './tonhon/App';
// import AppHKT48 from './hkt48/App';
// import AppHKT48_VIP from './hkt48_vip/App';
// import AppChaeunwoo from './cha-eun-woo/App';
// import AppEarthmix from './earthmix/App';
// import AppFishuponthesky from './fishuponthesky/App';
// import AppBamBam from './bambam/App';
// import AppYoungjae from './youngjae/App';
// import AppLancome from './lancome/App';
// import AppMewbday from './mewbday/App';
// import AppDMDLand from './dmdland/App';
// import AppPolca from './polca/App';
// import AppNunew from './nunew/App';
// import AppShootingstar from './shooting-star/App';
// import AppKinnporsche from './kinnporsche/App';
// import AppKinnporsche2 from './kinnporsche/App2';
// import AppCaptureHappiness from './lancome-capturehappiness/App';
// import AppOnfriendcity from './onfriendcity/App';
// import AppLoveoutloud from './loveoutloud/App';
// import AppZeepruk from './zee-pruk/App';
// import AppLancomeHappinessChapter from './lancomehappinesschapter/App';
// import AppFeelFanFun from './feelfanfun/App';
// import AppSeasonoflove from './seasonoflove/App';
// import AppSidebysidebrightwin from './sidebysidebrightwin/App';
// import AppCutiepie_ep1 from './cutiepie_ep1/App';
// import AppThedebutante from './thedebutante/App';
// import AppCutielovefanconcert from './cutielovefanconcert/App';
// import AppMyschoolpresident from './myschoolpresident/App';
// import AppMyschoolpresident2 from './myschoolpresident2/App';
// import AppFreenbecky from './freenbecky/App';
// import AppBeluca from './beluca/App';
// import AppOrntara from './orntara/App';
// import AppThereborn from './thereborn/App';
// import Applol2023_round1 from './lol2023_round1/App';
// import Applol2023_round2 from './lol2023_round2/App';
// import Appsarocha_round1 from './sarocha_1/App';
// import Appsarocha_round2 from './sarocha_2/App';
// import AppGemini_round1 from './gemini_round1/App';
// import AppGemini_round2 from './gemini_round2/App';
// import AppFanday_round1 from './fanday_round1/App';
// import AppFanday_round2 from './fanday_round2/App';
// import AppFanday_round3 from './fanday_round3/App';
// import AppFanday_round4 from './fanday_round4/App';
// import AppFanday_round5 from './fanday_round5/App';
// import AppSpecialstage from './specialstage/App';
// import AppFreenBecky_jp from './freenbecky_jp/App';
// import AppTheKrist1 from './thekrist1/App';
// import AppTheKrist2 from './thekrist2/App';
// import AppStarlympic from './starlympic/App';
// import AppHikingbecky from './hikingbecky/App';
// import AppDMDLand2 from './dmdland2/App';
// import AppDMDLnad2_onsale from './dmdland2_onsale/App';
// import AppThesign from './thesign/App';
// import AppWinholidate from './winholidate/App';
// import AppNanon from './nanon/App';
// import AppLasttwilight from './lasttwilight/App';
// import AppThesignlostinthejungle from './thesignlostinthejungle/App';
// import AppThesigninjapan_rd1 from './thesigninjapan_rd1/App';
// import AppThesigninjapan_rd2 from './thesigninjapan_rd2/App';
// import AppBabii from './babii/App';
// import AppPolca from './polca/App';
// import AppLoveoutloud_Rd1 from './loveoutloud2024_round1/App';
// import AppLoveoutloud_Rd2 from './loveoutloud2024_round2/App';
// import AppThesignencore from './thesignencore/App';

// import AppAuthenticmile from './authenticmile/App';
// import AppPeraya from './peraya/App';
// import AppBilly from './billy/App';
// import AppBlankTheseries from './blanktheseries/App';
// import AppBouncy from './bouncy/App';
import AppTheloyalpin from './theloyalpin/App';
// import AppFinaldream from './finaldream/App';
import AppWeAreForeverFanconRoundOne from './weareforeverfancon_round1/App'
import AppWeAreForeverFanconRoundTwo from './weareforeverfancon_round2/App'

import AppPoohPavelFirstDate from './poohpavelfirstdate/App'
import AppSarochaBackstage from './sarochabackstage/App'
import AppTheFreenairyOracle from './thefreenairyoracle/App'
import AppYokoBirthdayParty from './yokobirthdayparty/App2'
import AppGeminiFourthTheWorldConcert from './geminifourthruntheworldconcert/App2'

const AppProvider = ({ eventid }) => (
  <StoreContextProvider>
    {/* {eventid.toString() === '436' && <AppBamBam eventid={eventid} />}
    {eventid.toString() === '432' && <AppFishuponthesky eventid={eventid} />}
    {eventid.toString() === '413' && <AppEarthmix eventid={eventid} />}
    {eventid.toString() === '420' && <AppChaeunwoo eventid={eventid} />}
    {eventid.toString() === '005' && <AppHKT48_VIP eventid={eventid} />}
    {eventid.toString() === '414' && <AppHKT48 eventid={eventid} />}
    {eventid.toString() === '365' && <AppTonHon eventid={eventid} />} */}
    {/* {eventid.toString() === '305' && <AppBF eventid={eventid} />} */}
    {/* {eventid.toString() === '970' && <AppHan eventid={eventid} />} */}
    {/* {eventid.toString() === '361' && <AppTT eventid={eventid} />} */}
    {/* {eventid.toString() === '002' && <AppLC eventid={eventid} />} */}
    {/* {eventid.toString() === '003' && <AppMew eventid={eventid} />}
    {eventid.toString() === '381' && <AppBounPrem eventid={eventid} />}
    {eventid.toString() === '464' && <AppYoungjae eventid={eventid} />}
    {eventid.toString() === '015' && <AppLancome eventid={eventid} />}
    {eventid.toString() === '456' && <AppMewbday eventid={eventid} />} */}
    {/* {eventid.toString() === '016' && <AppCaptureHappiness eventid={eventid} />} */}
    {/* {eventid.toString() === '017' && <AppCaptureHappiness eventid={eventid} />}
    {eventid.toString() === '569' && <AppDMDLand eventid={eventid} />}
    {eventid.toString() === '565' && <AppPolca eventid={eventid} />}
    {eventid.toString() === '018' && <AppNunew eventid={eventid} />}
    
    {eventid.toString() === '623' && <AppKinnporsche eventid={eventid} />}
    {eventid.toString() === '019' && <AppKinnporsche2 eventid={eventid} />} */}
    {/* {eventid.toString() === '577' && <AppShootingstar eventid={eventid} />} */}
    {/* {eventid.toString() === '600' && <AppOnfriendcity eventid={eventid} />}
    {eventid.toString() === '613' && <AppLoveoutloud eventid={eventid} />}
    {eventid.toString() === '677' && <AppZeepruk eventid={eventid} />} */}
    {/* {eventid.toString() === '020' && <AppLancomeHappinessChapter eventid={eventid} />} */}
    {/* {eventid.toString() === '697' && <AppFeelFanFun eventid={eventid} />} */}
    {/* {eventid.toString() === '831' && <AppSeasonoflove eventid={eventid} />} */}
    {/* {eventid.toString() === '747' && <AppSidebysidebrightwin eventid={eventid} />} */}
    {/* {eventid.toString() === '867' && <AppCutiepie_ep1 eventid={eventid} />}
    {eventid.toString() === '898' && <AppThedebutante eventid={eventid} />} */}
    {/* {eventid.toString() === '902' && <AppCutielovefanconcert eventid={eventid} />}
    {eventid.toString() === '886' && <AppMyschoolpresident eventid={eventid} />}
    {eventid.toString() === '023' && <AppMyschoolpresident2 eventid={eventid} />}
    {eventid.toString() === '949' && <AppFreenbecky eventid={eventid} />}
    {eventid.toString() === '970' && <AppBeluca eventid={eventid} />}
    {eventid.toString() === '024' && <AppOrntara eventid={eventid} />}
    {eventid.toString() === '025' && <AppThereborn eventid={eventid} />} */}
    {/* {eventid.toString() === '423_1' && <Applol2023_round1 eventid={eventid} />}
    {eventid.toString() === '423_2' && <Applol2023_round2 eventid={eventid} />} */}
    {/* {eventid.toString() === '552_1' && <Appsarocha_round1 eventid={eventid} />}
    {eventid.toString() === '552_2' && <Appsarocha_round2 eventid={eventid} />} */}
    {/* {eventid.toString() === '514_1' && <AppGemini_round1 eventid={eventid} />}
    {eventid.toString() === '514_2' && <AppGemini_round2 eventid={eventid} />}
    {eventid.toString() === '578_1' && <AppFanday_round1 eventid={eventid} />}
    {eventid.toString() === '578_2' && <AppFanday_round2 eventid={eventid} />}
    {eventid.toString() === '578_3' && <AppFanday_round3 eventid={eventid} />}
    {eventid.toString() === '578_4' && <AppFanday_round4 eventid={eventid} />}
    {eventid.toString() === '578_5' && <AppFanday_round5 eventid={eventid} />}
    {eventid.toString() === '560' && <AppSpecialstage eventid={eventid} />}
    {eventid.toString() === '642' && <AppFreenBecky_jp eventid={eventid} />} */}
    {/* {eventid.toString() === '601_1' && <AppTheKrist1 eventid={eventid} />}
    {eventid.toString() === '601_2' && <AppTheKrist2 eventid={eventid} />} */}
    {/* {eventid.toString() === '683' && <AppStarlympic eventid={eventid} />} */}
    {/* {eventid.toString() === '705' && <AppHikingbecky eventid={eventid} />} */}
    {/* {eventid.toString() === '733' && <AppDMDLand2 eventid={eventid} />}
    {eventid.toString() === '779' && <AppDMDLnad2_onsale eventid={eventid} />}
    {eventid.toString() === '766' && <AppThesign eventid={eventid} />}
    {eventid.toString() === '743' && <AppWinholidate eventid={eventid} />} */}
    {/* {eventid.toString() === '777' && <AppNanon eventid={eventid} />}
    {eventid.toString() === '823' && <AppLasttwilight eventid={eventid} />}
    {eventid.toString() === '859' && <AppThesignlostinthejungle eventid={eventid} />}
    {eventid.toString() === '897_1' && <AppThesigninjapan_rd1 eventid={eventid} />}
    {eventid.toString() === '897_2' && <AppThesigninjapan_rd2 eventid={eventid} />} */}
    {/* {eventid.toString() === '791' && <AppBabii eventid={eventid} />}
    {eventid.toString() === '026' && <AppPolca eventid={eventid} />} */}
    {/* {eventid.toString() === '883_1' && <AppLoveoutloud_Rd1 eventid={eventid} />}
    {eventid.toString() === '883_2' && <AppLoveoutloud_Rd2 eventid={eventid} />} */}
    {/* {eventid.toString() === '989' && <AppThesignencore eventid={eventid} />} */}
    {/* {eventid.toString() === '030' && <AppAuthenticmile eventid={eventid} />} */}
    {/* {eventid.toString() === '933' && <AppPeraya eventid={eventid} />} */}
    {/* {eventid.toString() === '029' && <AppBilly eventid={eventid} />} */}
    {/* {eventid.toString() === '031' && <AppBlankTheseries eventid={eventid} />} */}
    {/* {eventid.toString() === '032' && <AppBouncy eventid={eventid} />} */}
    {/* {eventid.toString() === '033' && <AppTheloyalpin eventid={eventid} />} */}
    {/* {eventid.toString() === '034' && <AppFinaldream eventid={eventid} />} */}
    {eventid.toString() === '035_1' && <AppWeAreForeverFanconRoundOne eventid={eventid} />}
    {eventid.toString() === '035_2' && <AppWeAreForeverFanconRoundTwo eventid={eventid} />}
    {eventid.toString() === '036' && <AppPoohPavelFirstDate eventid={eventid} />}
    {eventid.toString() === '037' && <AppSarochaBackstage eventid={eventid} />}
    {eventid.toString() === '038' && <AppTheFreenairyOracle eventid={eventid} />}
    {eventid.toString() === '039' && <AppYokoBirthdayParty eventid={eventid} />}
    {eventid.toString() === '040' && <AppGeminiFourthTheWorldConcert eventid={eventid} />}

  </StoreContextProvider>
);

export default AppProvider;